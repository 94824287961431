import { HashLink } from "react-router-hash-link";

export const SecondPartOfFooter = () => {
  return (
    <>

      <footer style={{ position: "relative" }} className="page__footer">
        <article className="page__section">
          <img
            src="images/contact-up-img.png"
            className="page-img-contact"
            alt="contact-us"
          />
          <div
            style={{ position: "relative", zIndex: "100" }}
            className="footer__list-container"
          >
            <p className="footer__subtitle-experience">
              Experience the future with a crazy experienced team.
            </p>
            <ul className="footer__list">
              <li className="footer__item">
                <HashLink smooth to="/#about" className="footer__link">
                  About us
                </HashLink>
              </li>
              <li className="footer__item">
                <HashLink smooth to="/#partners" className="footer__link">
                  Projects
                </HashLink>
              </li>
              <li className="footer__item">
                <HashLink smooth to="/#services" className="footer__link">
                  Services
                </HashLink>
              </li>
              <li className="footer__item">
                <HashLink smooth to="/#our-works" className="footer__link">
                  Our works
                </HashLink>
              </li>
              <li className="footer__item">
                <HashLink smooth to="/#contacts" className="footer__link">
                  Contacts
                </HashLink>
              </li>
            </ul>
          </div>
          <div className="footer-logo-container">
            <a
              href="https://www.instagram.com/sobex.agency?igsh=YzhlaXJzbW5wMWRh&utm_source=qr"
              target="_blank"
              rel="noreferrer"
            >
              <img src="images/instagram.svg" alt="instagram" />
            </a>
            <a href="/">
              <img src="images/telegram.svg" alt="telegram" />
            </a>
            <a
              href="https://www.linkedin.com/feed/"
              target="_blank"
              rel="noreferrer"
            >
              <img src="images/linkedin.svg" alt="linkedin" />
            </a>
          </div>
        </article>
      </footer>
      <footer className="footer footer-second">
        <article className="page__section footer-container-second">
          <h2 className="footer__subtitle">
            ©2023 sobeX, Worldwide. All rights reserved.
          </h2>
          <div className="footer__subtitle-container">
            <h2 className="footer__subtitle">Privacy Policy</h2>
            <h2 className="footer__subtitle">·</h2>
            <h2 className="footer__subtitle">Terms of Conditions</h2>
          </div>
        </article>
      </footer>
    </>
  );
};
