import { NavLink } from "react-router-dom";
import { ColoursSection } from "../ColoursSection/ColoursSection";
import { PageFooterButton } from "../PageFooterButton/PageFooterButton";
import { PageSection } from "../PageSection/PageSection";
import "./merchain.scss";
import { SecondPartOfFooter } from "../SecondPartOfFooter/SecondPartOfFooter";
import { MobileMenu } from "../MobileMenu/MobileMenu";
import { HashLink } from "react-router-hash-link";
import { PageListLinks } from "../PageListLinks/PageListLinks";
// import "./match.scss";

export const MerchainPage = () => {
  const colors = [
    {
      color: "#BEB8BD",
      codeName: "Silver",
      name: "Silver",
      subtitleNames: ["#BEB8BD", "R190, G189, B189"],
      order: "first",
      textColor: "black",
    },
    {
      color: "#6BB6FC",
      codeName: "MayaBlue",
      name: "Maya Blue",
      subtitleNames: ["#6BB6FC", "R107, G182, B252"],
      order: "second",
      textColor: "black",
    },
    {
      color: "#3B3D43",
      codeName: "Vulcan",
      name: "Vulcan",
      subtitleNames: ["#3B3D43", "R59, G61, B67"],
      order: "third",
      textColor: "white",
    },
    {
      color: "#B69ADA",
      codeName: "BilobaFlower",
      name: "Biloba Flower",
      subtitleNames: ["#B69ADA", "R182, G154, B218"],
      order: "fourth",
      textColor: "black",
    },
    {
      color: "#FFFFFF",
      codeName: "White",
      name: "White",
      subtitleNames: ["#FFFFFF", "R255, G255, B255"],
      order: "fifth",
      textColor: "black",
    },
    {
      color: "#EB75A0",
      codeName: "Carissma",
      name: "Carissma",
      subtitleNames: ["#EB75A0", "R235, G117, B160"],
      order: "sixth",
      textColor: "black",
    },
  ];

  return (
    <>
      <header className="page__header__main-container">
        <div className="page__header-container">
          <MobileMenu />
          <div className="page__header">
            <NavLink to="/" className="page-logo-container">
              <img
                src="images/S-letter.svg"
                className="page-logo page-logo-S"
                alt="logo"
              />
              <img
                src="images/O-letter.svg"
                className="page-logo page-logo-O"
                alt="logo"
              />
              <img
                src="images/B-letter.svg"
                className="page-logo page-logo-B"
                alt="logo"
              />
              <img
                src="images/E-letter.svg"
                className="page-logo page-logo-E"
                alt="logo"
              />
              <img
                src="images/X-letter.svg"
                className="page-logo page-logo-X"
                alt="logo"
              />
            </NavLink>
            <PageListLinks />
          </div>
          <div className="safe__main-container">
            <div className="lumina__title-container">
              <h1
                className="greit__title centuro__title"
                style={{
                  fontSize: window.innerWidth <= 768 ? "80px" : "180px",
                  zIndex: "10",
                }}
              >
                MERCHAIN
              </h1>
              <h2
                className="shell__subtitle"
                style={{
                  zIndex: "10",
                }}
              >
                Merchain
              </h2>
            </div>
            <img
              src="images/merchain-notebook-header.png"
              className="centuro-img-notebook"
              alt="notebook-img"
            />
            <img
              src="images/shadow-img-merchain.png"
              className="merchain-img-shadow"
              alt="shadow-img"
            />
            <img
              src="images/merchain-img-header.svg"
              className="lumina-img-header"
              alt="image-merchain"
            />
          </div>
        </div>
      </header>
      <PageSection
        boldTitle={"MERCHAIN."}
        title={"BRAND and WEBSITE DESIGN"}
        paragraph={
          <>
            Development Project for a Cryptocurrency Merchant Platform<br></br>
            <br></br>
            Our team developed an innovative cryptocurrency merchant platform
            designed to enable seamless integration of multiple payment services
            for websites. This solution provides businesses with the tools to
            accept a wide range of cryptocurrencies securely and efficiently,
            enhancing customer payment flexibility and improving the overall
            user experience.<br></br>
            <br></br>
            What We Did:<br></br>
            <br></br>
            Scalable Payment Integration — developed a system that supports the
            integration of various payment services, allowing businesses to
            connect multiple cryptocurrency gateways to their websites.<br></br>
            <br></br>
            User-Friendly Dashboard — designed an intuitive dashboard for
            merchants to monitor transactions, manage connected services, and
            generate financial reports with ease.<br></br>
            <br></br>
            Multi-Currency Support — implemented support for a wide range of
            cryptocurrencies, giving businesses and their customers the
            flexibility to choose their preferred digital assets for payments.
            <br></br>
            <br></br>
            Robust Security Measures — integrated advanced security protocols,
            including encryption, fraud detection, and secure API connections,
            to ensure safe and reliable transactions.<br></br>
            <br></br>
            Customizable APIs for Businesses — created flexible API solutions
            that allow developers to tailor integrations to the specific needs
            of their platforms, ensuring seamless functionality across different
            systems.<br></br>
            <br></br>
            Real-Time Transaction Monitoring — implemented tools for live
            tracking of payments, giving merchants and users immediate
            visibility into the status of their transactions.<br></br>
            <br></br>
            Result:<br></br>
            The cryptocurrency merchant platform empowers businesses to accept
            digital payments easily and securely. By simplifying the integration
            process and providing robust tools for transaction management, this
            platform is helping businesses embrace the future of digital
            commerce. Its flexibility, scalability, and security features make
            it a valuable asset for companies looking to expand their payment
            options.
          </>
        }
        year={"2024"}
        industry={
          <>
            Product Design<br></br>API Integration<br></br>Frontend and Backend
            <br></br>Payment Systems and Security<br></br>Custom Web
            Applications
          </>
        }
        projectDescription={
          <>
            Universal cryptocurrency payment gateway<br></br>Secure and scalable
            payment integration for websites<br></br>Supporting multi-currency
            transactions for businesses
          </>
        }
      />
      <ColoursSection colours={colors} />
      <img src="images/qwerty-img.png" className="page-img" alt="qwerty" />
      <section className="page__section">
        <img
          src="images/merchain-phones.png"
          className="merchain-img-phones page-img"
          alt="phones"
        />
      </section>
      <div className="shell-img-mobile-container">
        <img
          src="images/merchain-img-1.svg"
          className="shell-img-mobile"
          alt="shell-img"
        />
        <img
          src="images/merchain-img-2.svg"
          className="shell-img-mobile"
          alt="shell-img"
        />
        <img
          src="images/merchain-img-3.svg"
          className="shell-img-mobile"
          alt="shell-img"
        />
      </div>
      <footer className="page__footer">
        <img
          src="images/merchain-notebooks-footer.png"
          className="merchain-img-notebooks page-img"
          alt="phones"
        />
        <PageFooterButton link="/digitalassetdealer" />
      </footer>
      <SecondPartOfFooter />
    </>
  );
};
