import React from "react";
import "./App.css";
// import { Header } from "./Components/Header/Header";
import { Outlet } from "react-router-dom";
import { HeaderPanel } from "./Components/HeaderPanel/HeaderPanel";

function App() {
  return (
    <div className="App">
      {/* <Header /> */}
      {/* <main> */}
      <HeaderPanel />
      <Outlet />
      {/* </main> */}
      {/* <Footer /> */}
    </div>
  );
}

export default App;
