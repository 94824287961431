import { MetaList } from "../MetaList/MetaList";
import "./footer.scss";
import { HashLink } from "react-router-hash-link";
// import { Link } from "react-router-dom";
import { Form } from "../Form/Form";
import { Link } from "react-scroll";

// Пользовательская функция для плавной прокрутки
const scrollWithOffset = (el: HTMLElement) => {
  const yCoordinate = el.getBoundingClientRect().top + window.pageYOffset;
  const yOffset = -100; // Настройка отступа (можно поставить 0, если не нужен)
  window.scrollTo({
    top: yCoordinate + yOffset,
    behavior: "smooth", // Плавная прокрутка
  });
};

export const Footer = () => {
  return (
    <>
      <section className="footer__section">
        <img
          src="images/decorate-title.png"
          className="footer-img__title"
          alt="decoration"
        />
      </section>
      <footer id="footer" className="footer footer-modify">
        <MetaList />
        <div className="footer__button-container">
          <Link
            to="form"
            smooth={true}
            duration={500}
            className="footer__button"
          >
            Let`s talk
          </Link>
        </div>
        <article className="form__article">
          <Form />
        </article>
        <article id="contacts" className="page__section">
          <div className="footer__list-container">
            <p className="footer__subtitle-experience">
              Experience the future with a crazy experienced team.
            </p>
            <ul className="footer__list">
              <li className="footer__item">
                <HashLink
                  smooth
                  scroll={(el) => scrollWithOffset(el)}
                  to="/#about"
                  className="footer__link"
                >
                  About us
                </HashLink>
              </li>
              <li className="footer__item">
                <HashLink
                  smooth
                  scroll={(el) => scrollWithOffset(el)}
                  to="/#partners"
                  className="footer__link"
                >
                  Projects
                </HashLink>
              </li>
              <li className="footer__item">
                <HashLink
                  smooth
                  scroll={(el) => scrollWithOffset(el)}
                  to="/#services"
                  className="footer__link"
                >
                  Services
                </HashLink>
              </li>
              <li className="footer__item">
                <HashLink
                  smooth
                  scroll={(el) => scrollWithOffset(el)}
                  to="/#our-works"
                  className="footer__link"
                >
                  Our works
                </HashLink>
              </li>
              <li className="footer__item">
                <HashLink
                  smooth
                  scroll={(el) => scrollWithOffset(el)}
                  to="/#contacts"
                  className="footer__link"
                >
                  Contacts
                </HashLink>
              </li>
            </ul>
          </div>
          <div className="footer-logo-container">
            <a
              href="https://www.instagram.com/sobex.agency?igsh=YzhlaXJzbW5wMWRh&utm_source=qr"
              target="_blank"
              rel="noreferrer"
            >
              <img src="images/instagram.svg" alt="instagram" />
            </a>
            <a href="/">
              <img src="images/telegram.svg" alt="telegram" />
            </a>
            <a
              href="https://www.linkedin.com/feed/"
              target="_blank"
              rel="noreferrer"
            >
              <img src="images/linkedin.svg" alt="linkedin" />
            </a>
          </div>
        </article>
      </footer>
      <footer className="footer footer-second" style={{ position: "relative" }}>
        <img
          src="images/contact-up-img.png"
          className="page-img-contact page-img-contact-main"
          alt="contact-us"
          style={{ position: "absolute", width: "100%" }}
        />
        <article className="page__section footer-container-second">
          <h2 className="footer__subtitle">
            ©2023 sobeX, Worldwide. All rights reserved.
          </h2>
          <div className="footer__subtitle-container">
            <h2 className="footer__subtitle">Privacy Policy</h2>
            <h2 className="footer__subtitle">·</h2>
            <h2 className="footer__subtitle">Terms of Conditions</h2>
          </div>
        </article>
      </footer>
    </>
  );
};
