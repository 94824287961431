import { useState } from "react";
import "./serviceCard.scss";
import { NavLink } from "react-router-dom";
import { ServiceCardType } from "../../types/card";

type Props = {
  card: ServiceCardType;
};

export const ServiceCard: React.FC<Props> = ({ card }) => {
  const { id, title, description, tags, link } = card;
  const [isHovered, setIsHovered] = useState(false);

  const handleMouseEnter = () => {
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };

  return (
    <div
      key={id}
      className={`card ${isHovered ? "card-expanded" : ""}`}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      {isHovered ? (
        <div className="card-content">
          <h2
            style={{
              fontFamily: `${card.fontFamily}`,
              fontWeight: `${card.fontWeight}`,
            }}
            className="card-title"
          >
            {title}
          </h2>
          <p className="description">
            {description}
          </p>
          <div className="tags">
            {tags.map((tag, index) => (
              <h3
                key={index}
                style={{ textTransform: "uppercase" }}
                className="page-design__item"
              >
                {tag}
              </h3>
            ))}
          </div>
          <NavLink
            to={`${link}`}
            style={{ position: "absolute", right: 0, bottom: "40px" }}
            className="page__button page__button-secondary card__button"
          >
            View details
          </NavLink>
        </div>
      ) : (
        <NavLink to={`${link}`} className="card-title">
          <h2
            style={{
              fontFamily: `${card.fontFamily}`,
              fontWeight: `${card.fontWeight}`,
            }}
            className="card-title"
          >
            {title}
          </h2>
        </NavLink>
      )}
    </div>
  );
};
