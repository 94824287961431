import React, { useState, useEffect } from "react";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/scrollbar";
import "./carousel.scss";

import { Navigation, Scrollbar, Mousewheel } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
import { services } from "../../utils/base";
import { NavLink } from "react-router-dom";

const Carousel = () => {
  // const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

  // useEffect(() => {
  //   const handleResize = () => {
  //     setIsMobile(window.innerWidth <= 768);
  //   };

  //   window.addEventListener("resize", handleResize);

  //   return () => {
  //     window.removeEventListener("resize", handleResize);
  //   };
  // }, []);

  return (
    <div style={{ margin: "0 auto", width: "100%" }} className="carousel">
      {/* {isMobile ? ( */}
      {/* // Свипер для экранов >768px */}
      <Swiper
        modules={[Navigation, Scrollbar, Mousewheel]}
        slidesPerView={3.5}
        spaceBetween={20}
        scrollbar={{ el: ".swiper-scrollbar", draggable: true }}
        mousewheel={{ forceToAxis: true }}
        breakpoints={{
          309: { slidesPerView: 2 },
          768: { slidesPerView: 3 },
          1024: { slidesPerView: 4 },
        }}
      >
        {services.map((item, index) => (
          <SwiperSlide key={index}>
            <NavLink
              to={item.link}
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                boxSizing: "border-box",
                // height: "100px",
                fontFamily: `${item.fontFamily}`,
                fontWeight: `${item.fontWeight}`,
              }}
              className="carousel__title"
            >
              {item.name}
            </NavLink>
          </SwiperSlide>
        ))}
      </Swiper>
    </div>
    //   ) : (
    //     // Слайдер через overflow: scroll для экранов <=768px
    //     <div className="scrollable-carousel">
    //       {services.map((item, index) => (
    //         <div key={index}>
    //           <NavLink
    //             style={{
    //               display: "flex",
    //               justifyContent: "center",
    //               alignItems: "center",
    //               boxSizing: "border-box",
    //               height: "100px",
    //               fontFamily: `${item.fontFamily}`,
    //               fontWeight: `${item.fontWeight}`,
    //               textDecoration: "none",
    //               color: "#fff",
    //             }}
    //             className="carousel__title"
    //             to={item.link}
    //           >
    //             {item.name}
    //           </NavLink>
    //         </div>
    //       ))}
    //     </div>
    //   )}
    // </div>
  );
};

export default Carousel;
