import "./header.scss";
import "../../styles/page.scss";
import { MobileMenu } from "../MobileMenu/MobileMenu";
import { Link } from "react-scroll";

export const Header = () => {
  return (
    <header className="header">
      <MobileMenu />

      <div className="header-container-decoration">
        <div className="header__title-decoration-container">
          <h2 className="header__title-decoration">
            An integrated digital and fintech agency offering a full range of
            services
          </h2>
          <div className="header-circle"></div>
          <img
            src="images/header-shadow.png"
            className="header-shadow header-shadow-first"
            alt="shadow"
          />
          <img
            src="images/header-shadow-1.png"
            className="header-shadow header-shadow-second"
            alt="shadow"
          />
          <img
            src="images/header-shadow-2.png"
            className="header-shadow header-shadow-third"
            alt="shadow"
          />
          <img
            src="images/header-shadow-3.png"
            className="header-shadow header-shadow-fourth"
            alt="shadow"
          />
          <img
            src="images/header-shadow-4.png"
            className="header-shadow header-shadow-fourth"
            alt="shadow"
          />
        </div>
      </div>
      <div className="header__main-container">
        <div>
          <h1 className="header__title header__title-desktop header__title-first">
            Innovating Excellence
          </h1>
          <h1 className="header__title header__title-desktop header__title-second">
            in Digital Solutions
          </h1>
          <h1 className="header__title header__title-mobile header__title-third">
            Innovating
          </h1>
          <h1 className="header__title header__title-mobile header__title-fourth">
            Excellence
          </h1>
          <h1 className="header__title header__title-mobile header__title-fifth">
            in Digital
          </h1>
          <h1 className="header__title header__title-mobile header__title-sixth">
            Solutions
          </h1>
        </div>
        <div className="header__button-container">
          <Link
            to="form"
            smooth={true}
            duration={500}
            className="page__button header__button"
          >
            Let`s talk
          </Link>
          <p className="page__subtitle header__subtitle">
            Delivering amazing experiences is nothing but our goal, we are
            crafting, building valuable technologies for brands, digital
            products and any human being.
          </p>
        </div>
      </div>
      <img
        id="about"
        src="images/decoration-bottom.png"
        className="header-bottom"
        alt="decoration"
      />
    </header>
  );
};
