export const cardData = [
  {
    id: 1,
    url: "meta-verified.com",
    link: "/meta-verified",
    description: "UX&UI Design",
    duration: "8 days",
    year: 2023,
    imagePath: "images/card-img-1.png",
  },
  {
    id: 2,
    url: "safe.wallet",
    link: "/safe-wallet",
    description: "Full Development",
    duration: "3 weeks",
    year: 2023,
    imagePath: "images/card-img-2.png",
  },
  {
    id: 3,
    url: "shell.com",
    link: "/shell",
    description: "UX&UI Design",
    duration: "6 days",
    year: 2023,
    imagePath: "images/card-img-3.png",
  },
  {
    id: 4,
    url: "lumina.com",
    link: "/lumina",
    description: "Full Development",
    duration: "3 weeks",
    year: 2023,
    imagePath: "images/card-img-4.png",
  },
  {
    id: 5,
    url: "lumina_pp.com",
    link: "/lumina-pp",
    description: "Full Development",
    duration: "3 weeks",
    year: 2023,
    imagePath: "images/card-img-5.png",
  },
  {
    id: 6,
    url: "Greit & Yard.com",
    link: "/greit-and-yard",
    description: "Full Development",
    duration: "3 weeks",
    year: 2023,
    imagePath: "images/card-img-6.png",
  },
  {
    id: 7,
    url: "centrino.com",
    link: "/centrino",
    description: "Full Development",
    duration: "3 weeks",
    year: 2023,
    imagePath: "images/card-img-7.png",
  },
  {
    id: 8,
    url: "boostchain.com",
    link: "/boostchain",
    description: "Brand and Website Design",
    duration: "2 weeks",
    year: 2023,
    imagePath: "images/card-img-8.png",
  },
  {
    id: 9,
    url: "boostchain-bot.com",
    link: "/boostchain-bot",
    description: "Brand and Website Design",
    duration: "2 weeks",
    year: 2023,
    imagePath: "images/card-img-9.png",
  },
  {
    id: 10,
    url: "crypto-store.com",
    link: "/crypto-store",
    description: "Brand and Website Design",
    duration: "2 weeks",
    year: 2023,
    imagePath: "images/card-img-10.png",
  },
  {
    id: 11,
    url: "digitalassetdealer.com",
    link: "/digitalassetdealer",
    description: "Brand and Website Design",
    duration: "4 weeks",
    year: 2023,
    imagePath: "images/card-img-11.png",
  },
  {
    id: 12,
    url: "blinkswap.com",
    link: "/blinkswap",
    description: "Brand and Website Design",
    duration: "3 weeks",
    year: 2023,
    imagePath: "images/card-img-12.png",
  },
  {
    id: 13,
    url: "inmatch.com",
    link: "/inmatch",
    description: "Brand and Website Design",
    duration: "4 weeks",
    year: 2023,
    imagePath: "images/card-img-13.png",
  },
  {
    id: 14,
    url: "merchain.com",
    link: "/merchain",
    description: "Brand and Website Design",
    duration: "3 weeks",
    year: 2023,
    imagePath: "images/card-img-14.png",
  },
];

export const steps = [
  {
    step: 1,
    title: "Discovery",
    details: [
      {
        section: "Creative Brief",
        description:
          "To better understand your goals with the new website, we will ask you to complete our custom and exclusive Creative Brief as the first step in creating your project. For optimal results, your personalized Brief will include questions about your audience, goals, CTAs, and your definition of success for our bespoke work.",
      },
      {
        section: "Digital Analysis",
        description:
          "It's essential for us to understand the goals of your website, as this is key to the project’s success. We start by discussing the objectives and target audience of your future site. Then, we develop a strategy aimed at meeting or even exceeding your expectations, taking analytics and user behavior into account.",
      },
      {
        section: "Strategy",
        description:
          "After studying your industry, references, and market trends, we develop a clear strategy for the next steps of the project and create a meeting schedule. This includes defining the visual direction and holding team brainstorming sessions.",
      },
    ],
  },
  {
    step: 2,
    title: "Design",
    details: [
      {
        section: "Concept Ideation",
        description:
          "As a unified multidisciplinary team, our web designers work closely with the branding department to ensure your website reflects the unique qualities and values of your brand. We apply current knowledge and design trends, thoroughly study your industry, and create a design that helps your business stand out.",
      },
      {
        section: "UX - Experience Design",
        description:
          "Using flowcharts and wireframes, we identify user motivation and prioritize elements on the site based on your audience’s needs. We implement cutting-edge innovations and design principles to position your site as a leader in your field.",
      },
      {
        section: "UI - Interface Design",
        description:
          "Next, we develop high-quality design screens that convey the concept and visually support your chosen web design direction. This process is adapted for various devices and responsive breakpoints.",
      },
      {
        section: "Prototype",
        description:
          "At the final stage of our detailed design process, we create clickable animated prototypes to visually demonstrate our vision of the web design and the proposed animations. If needed, we develop these using professional animation software.",
      },
    ],
  },
  {
    step: 3,
    title: "Development",
    details: [
      {
        section: "Systems Architecture",
        description:
          "We carefully develop and choose the optimal structure for the backend system that best suits your website. Once we determine the most effective solutions to achieve the desired result within the set timeframe, we move on to the development stage.",
      },
      {
        section: "Full Stack and CMS Integrations",
        description:
          "We use a variety of programming languages to provide flexibility for your website. Unlike many companies that rely on outdated approaches and templates, we create entirely unique solutions. Our team specializes in headless CMS — a content management system that operates independently from the frontend, making site management and content creation easier and more efficient.",
      },
      {
        section: "QA",
        description:
          "Once the design is complete, the website undergoes thorough testing using advanced technologies to help identify and correct any design inconsistencies and necessary adjustments.",
      },
      {
        section: "Launch",
        description:
          "This is one of the most exciting stages — we guide our clients through each step of the launch process, carefully overseeing every detail. Launching a website requires the utmost attention and discipline!",
      },
    ],
  },
  {
    step: 4,
    title: "Support",
    details: [
      {
        section: "Maintenance and Updates",
        description:
          "The relationship between a web design company and its clients should not end with the launch of a website. We ensure that your site continues working for you by maintaining secure hosting and providing daily data backups and security scans. We are also available for any updates, improvements, or expansions of your website. ",
      },
      {
        section: "Performance Monitoring",
        description:
          "In every project, we focus on performance and security. We aim to exceed modern web standards by enhancing accessibility, usability, responsiveness across all devices, and organic search visibility. This approach not only improves the user experience but also drives sales, reduces potential legal risks, and helps your brand grow.",
      },
      {
        section: "Robust Training",
        description:
          "We aim to make the process of creating and maintaining your website as straightforward as possible. Throughout our work, we share insights into how your website is structured. To support this, we provide training videos, manuals, and/or on-site training to help your team quickly acquire the skills needed to work with the new site.",
      },
    ],
  },
];

export const cryptoSteps = [
  {
    step: 1,
    title: "Discovery and Planning",
    details: [
      {
        section: "Market Research and Client Needs Assessment",
        description:
          "We start with an in-depth analysis of the cryptocurrency market and a discussion of project goals to define requirements and expectations.",
      },
      {
        section: "Digital Analysis",
        description:
          "We design the project architecture, including selecting blockchain, security protocols, KYC/AML standards, and external service integrations.",
      },
    ],
  },
  {
    step: 2,
    title: "Strategy and Concept Development",
    details: [
      {
        section: "Roadmap Creation",
        description:
          "We create a detailed project plan with key milestones, timelines, and tasks for development, testing, and launch.",
      },
      {
        section: "Interface and UX Design",
        description:
          "We develop prototypes and layouts to create an intuitive and functional interface suitable for your audience.",
      },
    ],
  },
  {
    step: 3,
    title: "Development",
    details: [
      {
        section: "Development and Integration",
        description:
          "We start building smart contracts, APIs, and interfaces for crypto wallets, exchanges, or other solutions.",
      },
      {
        section: "Testing and Quality Assurance",
        description:
          "We conduct detailed testing to identify issues and optimize performance, taking security requirements and regulatory compliance into account",
      },
    ],
  },
  {
    step: 4,
    title: "Security and Compliance",
    details: [
      {
        section: "Smart Contract and System Security Audit",
        description:
          "We test smart contracts and verify platform security to prevent vulnerabilities.",
      },
      {
        section: "Regulatory Compliance",
        description:
          "We ensure that security standards and regulatory requirements, including KYC/AML, are met before launch.",
      },
    ],
  },
  {
    step: 5,
    title: "Deployment and Launch",
    details: [
      {
        section: "Platform Launch",
        description:
          "We deploy the platform in a production environment, set up monitoring, and provide ongoing support.",
      },
      {
        section: "Training and Documentation",
        description:
          "We provide instructions and training for the client’s team to facilitate platform management and maintain operational efficiency.",
      },
    ],
  },
  {
    step: 6,
    title: "Ongoing Support and Optimization",
    details: [
      {
        section: "Technical Support and Updates",
        description:
          "We provide regular maintenance, bug fixes, and performance optimization.",
      },
      {
        section: "Security Monitoring and Enhancements",
        description:
          "We continuously monitor, update security measures, and adapt the platform to new threats and market changes.",
      },
    ],
  },
];

export const socialSteps = [
  {
    step: 1,
    title: "Research and Planning",
    description:
      "We conduct a thorough analysis of the target audience and competitors to identify the best channels and approaches for your advertising campaign. We also set clear goals and key performance indicators (KPIs) to focus efforts on achieving measurable results.",
  },
  {
    step: 2,
    title: "Strategy and Concept Development",
    description:
      "We create a content plan for regular posting and promotion to capture audience attention and increase brand loyalty. Additionally, we plan advertising campaigns and allocate the budget, setting timelines and defining key messages.",
  },
  {
    step: 3,
    title: "Content Creation",
    description:
      "We develop unique visual and textual materials that will attract attention and highlight the uniqueness of your brand. Interactive content (such as polls, contests, and challenges) is included to boost user engagement.",
  },
  {
    step: 4,
    title: "Campaign Launch and Management",
    description:
      "We set up targeted ads and launch campaigns to reach your target audience. We continuously monitor performance, manage the budget, and make adjustments to optimize results.",
  },
  {
    step: 5,
    title: "Analytics and Optimization",
    description:
      "We analyze campaign data and compare it to KPIs to identify the most effective elements. Based on this analysis, we optimize strategies and targeting to improve engagement and performance metrics.",
  },
  {
    step: 6,
    title: "Reporting and Support",
    description:
      "We prepare monthly reports with analytics and results so clients can track progress and effectiveness. We conduct regular reviews with clients, provide recommendations, and, if necessary, train the client’s team for ongoing audience engagement.",
  },
];

export const desingCards = [
  {
    id: 1,
    title: "Design",
    description:
      "We combine aesthetics and functionality in a unified embodiment",
    tags: [
      "product design",
      "ui/ux design",
      "re-branding",
      "graphic and brand design",
      "Prototyping",
      "UX Research"
    ],
    link: "/design",
    fontFamily: "IBM Plex Sans",
    fontWeight: "600",
  },
  {
    id: 2,
    title: "Mobile app development",
    description:
      "We design and develop mobile applications tailored to your business needs, ensuring an engaging user experience and robust functionality.",
    tags: [
      "ios/android development",
      "app testing",
      "app store optimization (aso)",
    ],
    link: "/mobile-app-development",
    fontFamily: "Tinos",
    fontWeight: "400",
  },
  {
    id: 3,
    title: "Web development",
    description: "Modednt digital tools for product work and management",
    tags: [
      "websites of any complexity",
      "frontend and backend",
      "cms and ecommerce",
      "technical support",
    ],
    link: "/web-development",
    fontFamily: "heebo",
    fontWeight: "200",
  },
  {
    id: 4,
    title: "Marketplace development",
    description:
      "Innovative solutions for seamless marketplace development and management",
    tags: [
      "b2b/b2c platforms",
      "payment system integration",
      "analytics and monitoring",
    ],
    link: "/marketplace-development",
    fontFamily: "IBM Plex Sans",
    fontWeight: "500",
  },
  {
    id: 5,
    title: "Cryptocurrency solutions",
    description:
      "We create innovative cryptocurrency solutions for your business, including smart contracts, decentralized applications, and more.",
    tags: [
      "crypto wallets",
      "crypto exchanges",
      "smart contracts",
      "security and compliance",
    ],
    link: "/cryptocurrency-solutions",
    fontFamily: "heebo",
    fontWeight: "200",
  },
  {
    id: 6,
    title: "Social media & advertising",
    description:
      "We provide social media and advertising solutions to enhance your brand`s online presence",
    tags: ["smm and content marketing", "social media analytics"],
    link: "/social-media-advertising",
    fontFamily: "Tinos",
    fontWeight: "400",
  },
];


export const services = [
  {
    id: 1,
    name: "Design",
    link: "/design",
    fontFamily: "IBM Plex Sans",
    fontWeight: "600",
  },
  {
    id: 2,
    name: "Mobile App Development",
    link: "/mobile-app-development",
    fontFamily: "Tinos",
    fontWeight: "400",
  },
  {
    id: 3,
    name: "Web Development",
    link: "/web-development",
    fontFamily: "heebo",
    fontWeight: "200",
  },
  {
    id: 4,
    name: "Marketplace Development",
    link: "/marketplace-development",
    fontFamily: "IBM Plex Sans",
    fontWeight: "500",
  },
  {
    id: 5,
    name: "Cryptocurrency Solutions",
    link: "/cryptocurrency-solutions",
    fontFamily: "heebo",
    fontWeight: "200",
  },
  {
    id: 6,
    name: "Social Media & Advertising",
    link: "/social-media-advertising",
    fontFamily: "Tinos",
    fontWeight: "400",
  },
];
