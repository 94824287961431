import { NavLink } from "react-router-dom";
import { ColoursSection } from "../ColoursSection/ColoursSection";
import { PageFooterButton } from "../PageFooterButton/PageFooterButton";
import { PageSection } from "../PageSection/PageSection";
import "./match.scss";
import { SecondPartOfFooter } from "../SecondPartOfFooter/SecondPartOfFooter";
import { MobileMenu } from "../MobileMenu/MobileMenu";
import { PageListLinks } from "../PageListLinks/PageListLinks";

export const MatchPage = () => {
  const colors = [
    {
      color: "#06254A",
      codeName: "DarkBlue",
      name: "Dark Blue",
      subtitleNames: ["#06254A", "R6, G37, B74"],
      order: "first",
      textColor: "white",
    },
    {
      color: "#FFA41C",
      codeName: "DarkTangerine",
      name: "Dark Tangerine",
      subtitleNames: ["#FFA41C", "R255, G164, B28"],
      order: "second",
      textColor: "black",
    },
    {
      color: "#33314E",
      codeName: "Paua",
      name: "Paua",
      subtitleNames: ["#33314E", "R51, G49, B78"],
      order: "third",
      textColor: "white",
    },
    {
      color: "#813D6D",
      codeName: "Violet",
      name: "Violet",
      subtitleNames: ["#813D6D", "R129, G61, B109"],
      order: "fourth",
      textColor: "white",
    },
    {
      color: "#FFFFFF",
      codeName: "White",
      name: "White",
      subtitleNames: ["#FFFFFF", "R255, G255, B255"],
      order: "fifth",
      textColor: "black",
    },
    {
      color: "#F7357D",
      codeName: "Fuxia",
      name: "Fuxia",
      subtitleNames: ["#F7357D", "R247, G53, B125"],
      order: "sixth",
      textColor: "white",
    },
  ];

  return (
    <>
      <header className="page__header__main-container">
        <div className="page__header-container">
          <MobileMenu />
          <div className="page__header">
            <NavLink to="/" className="page-logo-container">
              <img
                src="images/S-letter.svg"
                className="page-logo page-logo-S"
                alt="logo"
              />
              <img
                src="images/O-letter.svg"
                className="page-logo page-logo-O"
                alt="logo"
              />
              <img
                src="images/B-letter.svg"
                className="page-logo page-logo-B"
                alt="logo"
              />
              <img
                src="images/E-letter.svg"
                className="page-logo page-logo-E"
                alt="logo"
              />
              <img
                src="images/X-letter.svg"
                className="page-logo page-logo-X"
                alt="logo"
              />
            </NavLink>
            <PageListLinks />
          </div>
          <div className="safe__main-container">
            <div className="lumina__title-container">
              <h1
                className="greit__title centuro__title"
                style={{
                  position: "relative",
                  zIndex: "10",
                  fontSize: window.innerWidth <= 768 ? "100px" : "180px",
                }}
              >
                inMATCH
              </h1>
              <h2 className="shell__subtitle" style={{ zIndex: "10" }}>
                Sport Betting Exchange
              </h2>
            </div>
            <img
              src="images/inmatch-img-notebook-header.png"
              className="centuro-img-notebook"
              alt="notebook-img"
            />
            <img
              src="images/shadow-img-inmatch.png"
              className="match-img-shadow"
              alt="shadow-img"
            />
            <img
              src="images/match-img-header.svg"
              className="lumina-img-header"
              alt="image-header"
            />
          </div>
        </div>
      </header>
      <PageSection
        boldTitle={"INMATCH."}
        title={"BRAND and WEBSITE DESIGN"}
        paragraph={
          <>
            Development Project for a Sports Betting Platform Using
            Cryptocurrency – InMatch<br></br>
            <br></br>
            Our team developed a modern sports betting platform — InMatch, which
            utilizes cryptocurrencies and blockchain technology to enhance user
            experience. The project also included ICO preparation and the
            pre-launch phase of the platform.<br></br>
            <br></br>
            What We Did:<br></br>
            <br></br>
            Innovative Platform Design — created a modern, user-friendly
            interface that allows users to place bets, track results, and manage
            their accounts with ease.<br></br>
            <br></br>
            Blockchain Integration for Transparency — implemented blockchain
            technology to ensure security, transparency, and the ability for
            users to verify all transactions and bets.<br></br>
            <br></br>
            ICO Preparation — developed tokenomics and strategic materials for
            the ICO phase, including whitepapers, promotional materials, and
            investor presentations.<br></br>
            <br></br>
            Enhanced Security Features — integrated multi-factor authentication,
            encryption protocols, and smart contract functionality to protect
            users’ data and funds.<br></br>
            <br></br>
            Real-Time Analytics and Updates — built systems to deliver live data
            such as results, odds, and transaction tracking, making the betting
            process more engaging and dynamic.<br></br>
            <br></br>
            Support for Multiple Cryptocurrencies — enabled the use of various
            cryptocurrencies for deposits, withdrawals, and bets, offering users
            maximum flexibility.<br></br>
            <br></br>
            Result:<br></br>
            The InMatch platform is ready to revolutionize the sports betting
            industry by combining the excitement of betting with the security
            and transparency of blockchain technology. Successful ICO
            preparation and innovative platform design provide a strong
            foundation for its official launch.
          </>
        }
        year={"2024"}
        industry={
          <>
            Product Design<br></br>API Integration<br></br>Payment Systems and
            <br></br>Security<br></br>Smart Contracts<br></br>Security and
            Compliance
          </>
        }
        projectDescription={
          <>
            Blockchain-based sports betting platform<br></br>Development of a
            secure and transparent betting experience<br></br>Integration of
            cryptocurrency for seamless transactions
          </>
        }
      />
      <ColoursSection colours={colors} />
      <img src="images/qwerty-img.png" className="page-img" alt="qwerty" />
      <img
        src="images/qwerty-img-2.png"
        className="boost-img-qwerty page-img"
        alt="qwerty"
      />
      <section className="page__section">
        <img
          src="images/match-phones.png"
          className="match-img-phones page-img"
          alt="phones"
        />
      </section>
      <div className="shell-img-mobile-container">
        <img
          src="images/match-img-1.svg"
          className="shell-img-mobile"
          alt="shell-img"
        />
        <img
          src="images/match-img-2.svg"
          className="shell-img-mobile"
          alt="shell-img"
        />
        <img
          src="images/match-img-3.svg"
          className="shell-img-mobile"
          alt="shell-img"
        />
      </div>
      <footer className="page__footer">
        <img
          src="images/match-notebooks.png"
          className="match-img-notebooks page-img"
          alt="phones"
        />
        <PageFooterButton link="/merchain" />
      </footer>
      <SecondPartOfFooter />
    </>
  );
};
