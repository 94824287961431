import "./MetaCard.scss";
import { CardData } from "../../types/card";
import { NavLink } from "react-router-dom";
import { useState } from "react";

type Props = {
  data: CardData;
};

export const MetaCard: React.FC<Props> = ({ data }) => {
  const [isHovered, setIsHovered] = useState(false);

  return (
    <>
      <div
        className="meta__card"
        style={{
          transition: "all 0.5s ease",
          backgroundImage: isHovered ? `url(${data.imagePath})` : "none",
          backgroundColor: isHovered ? "transparent" : "#fff",
        }}
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
      >
        {isHovered ? (
          <>
            <h2 className="meta__card__number">{data.id}</h2>
            <NavLink to={`${data.link}`} className="meta__card__button">
              {data.url}
            </NavLink>
            <h4 className="meta__card__post">{data.description}</h4>
            <div className="meta__card__post-container">
              <h4 className="meta__card__post">{`Total: ${data.duration}`}</h4>
              <h4 className="meta__card__post meta__card__post-second">
                {data.year}
              </h4>
            </div>
          </>
        ) : (
          <>
            <h2 className="meta__card__number meta__card__number-hover">
              {data.id}
            </h2>
            <NavLink to={`${data.link}`} className="meta__card__button meta__card__button-hover">
              {data.url}
            </NavLink>
            <h4 className="meta__card__post meta__card__post-hover">
              {data.description}
            </h4>
            <div className="meta__card__post-container meta__card__post-container-hover">
              <h4 className="meta__card__post meta__card__post-hover">{`Total: ${data.duration}`}</h4>
              <h4 className="meta__card__post-hover meta__card__post-hover-second">
                {data.year}
              </h4>
            </div>
          </>
        )}
      </div>
    </>
  );
};
