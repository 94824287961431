import React, { useState } from "react";
import "./form.scss";
import '../../styles/page.scss';

export const Form = () => {
  const [telegram, setTelegram] = useState("");
  const [status, setStatus] = useState("");

  const handleSubmit = async (e: any) => {
    e.preventDefault();

    if (!telegram.trim()) {
      setStatus("Please enter a valid Telegram username.");
      return;
    }

    try {
      const response = await fetch(
        "https://web.sobex.agency/api/send-telegram",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ telegram }),
        }
      );

      if (response.ok) {
        setStatus("Message sent successfully!");
        setTelegram(""); // Очищаем поле после успешной отправки
      } else {
        const errorData = await response.json();
        setStatus(`Error: ${errorData.message || "Something went wrong."}`);
      }
    } catch (error: any) {
      setStatus(`Error: ${error.message}`);
    }
  };

  return (
    <form id="form" className="page__section form" onSubmit={handleSubmit}>
      <h2 className="form__title">We look forward to connecting with you!</h2>
      <div className="form__field-container">
        <input
          className="form__field"
          placeholder="@telegram_nickname"
          value={telegram}
          onChange={(e) => setTelegram(e.target.value)}
        />
        <button className="form__button" type="submit">
          Submit
        </button>
      </div>
      {status && <p className="form__status">{status}</p>}
    </form>
  );
};
