import { NavLink } from "react-router-dom";
import { Link } from "react-scroll";

export const HeaderPanel = () => {
  return (
    <div className="header-container">
      <NavLink to="/" className="page-logo-container">
        <img
          src="images/S-letter.svg"
          className="page-logo page-logo-S"
          alt="logo"
        />
        <img
          src="images/O-letter.svg"
          className="page-logo page-logo-O"
          alt="logo"
        />
        <img
          src="images/B-letter.svg"
          className="page-logo page-logo-B"
          alt="logo"
        />
        <img
          src="images/E-letter.svg"
          className="page-logo page-logo-E"
          alt="logo"
        />
        <img
          src="images/X-letter.svg"
          className="page-logo page-logo-X"
          alt="logo"
        />
      </NavLink>
      <ul className="header__list">
        <li className="header__item">
          <Link to="about" smooth={true} duration={500} className="page__link">
            About us
          </Link>
        </li>
        <li className="header__item">
          <Link
            to="partners"
            smooth={true}
            duration={500}
            className="page__link"
          >
            Our partners
          </Link>
        </li>
        <li className="header__item">
          <Link
            to="services"
            smooth={true}
            duration={500}
            className="page__link"
          >
            Services
          </Link>
        </li>
        <li className="header__item">
          <Link
            to="our-works"
            smooth={true}
            duration={500}
            className="page__link"
          >
            Our works
          </Link>
        </li>
        <li className="header__item">
          <Link
            to="contacts"
            smooth={true}
            duration={500}
            className="page__link"
          >
            Contacts
          </Link>
        </li>
      </ul>
    </div>
  );
};
